export const baseInputStyles = {
  backgroundColor: "transparent",
  border: "1px solid $darkSlateGrayOpacity30",
  borderRadius: "4px",
  color: "$textActive",
  fontFamily: "$body",
  fontSize: "16px",
  fontWeight: "600",
  height: "48px",
  outline: "2px solid transparent",
  padding: "$1 $2",
  transition: "all .2s ease-in-out",
  width: "100%",

  "&:focus": {
    outlineColor: "$primary",
  },

  "@bp1a": {
    fontSize: "14px",
  },
};
