import { createStitches } from "@stitches/react";

const systemUi =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

export const bodyFont = `Figtree, ${systemUi}`;
export const headingFont = `Figtree, ${systemUi}`;

const { css, styled, globalCss, createTheme, getCssText } = createStitches({
  media: {
    bp0: "(max-width: 639px)", // Mobile
    bp1: "(min-width: 640px) and (max-width: 767px)", // Mobile to Tablet
    bp1a: "(min-width: 640px)", // Mobile upwards
    bp2: "(min-width: 768px) and (max-width: 1023px)", // Tablet to Desktop
    bp2a: "(min-width: 768px)", // Tablet upwards
    bp3: "(min-width: 1024px) and (max-width: 1199px)", // Desktop to Laptop
    bp3a: "(min-width: 1024px)", // Desktop upwards
    bp4: "(min-width: 1200px) and (max-width: 1919px)", // Laptop to High Resolution Laptop
    bp4a: "(min-width: 1200px)", // Laptop upwards
    bp5: "(min-width: 1920px)", // High Resolution Laptop and
    print: "print", // High Resolution Laptop
  },
  theme: {
    colors: {
      primary: "#6C5DD3",
      primaryShade1: "#CFC8FF",
      primaryShade2: "#CFC8FF",
      primaryOpacity1: "#6C5DD3",
      primaryOpacity2: "#6C5DD3",
      primaryOpacity3: "#6C5DD3",
      primaryOpacity4: "#6C5DD3",

      secondary: "#0049C6",
      secondaryShade1: "#E7FAFF",
      secondaryShade2: "#CDF4FF",
      secondaryShade3: "#3F8CFF",
      secondaryShade4: "#3F8CFF",
      secondaryShade5: "#A0D7E7",
      secondaryShade6: "#244CB2",
      secondaryShade7: "#002685",

      accentMain: "#FFA2C0",
      accentShade1: "#FFB7F5",
      accentShade2: "#FFEBF6",
      accentOpacity: "#FFA2C0",

      accent2Main: "#FFCE73",
      accent2Shade1: "#FFE2AC",
      accent2Shade2: "#FFF3DC",
      accent2Shade3: "#FF9A7B",
      accent2Shade4: "#FF754C",
      accent2Shade5: "#FF754C",

      darkMain: "#1B1D21",
      darkShade1: "#E4E4E440",
      darkShade2: "#F0F3F6",
      darkShade3: "#E4E4E47F",
      darkShade4: "#E4E4E4",
      darkShade5: "#808191",
      darkOpacity: "#1B1D21",
      darkGrayOpacity40: "rgb(242 242 242 / 40%)",
      darkGray: "#f2f2f2",
      darkGoldenrod: "#5F75EE",
      darkOliveGreen: "#a49dd0",
      darkSlateGrayOpacity30: "rgba(228, 228, 228, 1)",
      darkFullBlack: "rgba(255, 255, 255, 1)",
      darkLightGrayOpacity20: "rgba(99, 99, 99, 0.2)",
      darkSlate: "rgb(247, 247, 247)",

      white: "#FFFFFF",
      whiteOpacity1: "rgba(255, 255, 255, .05)",
      whiteOpacity2: "rgba(255, 255, 255, .1)",
      whiteOpacity3: "rgba(255, 255, 255, .2)",
      whiteOpacity4: "rgba(255, 255, 255, .4)",
      whiteOpacity5: "rgba(255, 255, 255, .6)",
      whiteOpacity6: "rgba(255, 255, 255, .6)",
      whiteOpacity7: "rgba(255, 255, 255, .8)",

      textActive: "#11142D",
      // textLight: "#808191",
      textLight: "#1B1D21",

      textDarkActive: "#fff",
      textDarkLight: "rgba(255, 255, 255, 0.7)",

      textDeActive: "#B2B3BD",

      success: "#7FBA7A",
      successLight: "#A5CEA1",

      //   Custom themes
      greyOnGreyBorderColor: "rgb(224, 224, 224)",

      repeatingItemBackgroundColor: "rgb(242 242 242 / 50%)",
    },
    fonts: {
      body: bodyFont,
      headings: headingFont,
    },
    shadows: {
      darkLightGrayOpacity20: "rgba(99, 99, 99, 0.2)",
    },
    space: {
      1: "8px",
      2: "16px",
      3: "24px",
      4: "32px",
      5: "40px",
      6: "48px",
      7: "54px",
    },
    zIndices: {
      e1: 1,
      e2: 2,
      eMax: 10,
      eAbsolute: 20,
    },
  },
  utils: {
    borderHelper: (position?: string) => ({
      [`border${position ? `-${position}` : ""}`]: "1px solid $darkShade2",
    }),

    repeatedItemBackground: () => ({
      backgroundColor: "$repeatingItemBackgroundColor",
      borderRadius: "4px",
      padding: "$2",
    }),
    /**
     *
     * @param cssProperty [Optional] CSS property to animate; 'all' by default
     * @returns
     */
    // transitionHelper: (cssProperty = "all") => ({
    //   transition: `${cssProperty} .2s ease-in-out`,
    // }),
  },
});

const darkTheme = createTheme("dark-theme", {
  colors: {
    primary: "#6C5DD3",
    primaryShade1: "#503AAB",
    primaryShade2: "#3E2993",
    primaryOpacity1: "#6C5DD320",
    primaryOpacity2: "#6C5DD340",
    primaryOpacity3: "#6C5DD360",
    primaryOpacity4: "#6C5DD380",

    secondary: "#0049C6",
    secondaryShade1: "#00379B",
    secondaryShade2: "#002570",
    secondaryShade3: "#001340",
    secondaryShade4: "#000810",
    secondaryShade5: "#001F60",
    secondaryShade6: "#001342",
    secondaryShade7: "#000724",

    accentMain: "#FFA2C0",
    accentShade1: "#FF70A0",
    accentShade2: "#FF4080",
    accentOpacity: "#FFA2C020",

    accent2Main: "#FFCE73",
    accent2Shade1: "#FFB050",
    accent2Shade2: "#FF9330",
    accent2Shade3: "#FF7618",
    accent2Shade4: "rgb(255 89 0 / 70%)",
    accent2Shade5: "rgb(255 89 0 / 10%)",

    darkMain: "#E4E4E4",
    darkShade1: "#B1B1B1",
    darkShade2: "#7F7F7F",
    darkShade3: "#4C4C4C",
    darkShade4: "#191919",
    darkShade5: "#7D7D7D",
    darkOpacity: "#E4E4E420",
    darkGrayOpacity40: "rgba(13, 13, 13, 0.40)",
    darkGray: "#0d0d0d",
    darkGoldenrod: "#A08A11",
    darkOliveGreen: "#5B622F",
    darkSlateGrayOpacity30: "#2727274D",
    darkFullBlack: "#000000",
    darkLightGrayOpacity20: "#9A9A9A33",
    darkSlate: "#080808",

    white: "#1B1D21",
    whiteOpacity1: "rgba(27, 29, 33, .05)",
    whiteOpacity2: "rgba(27, 29, 33, .1)",
    whiteOpacity3: "rgba(27, 29, 33, .2)",
    whiteOpacity4: "rgba(27, 29, 33, .4)",
    whiteOpacity5: "rgba(27, 29, 33, .6)",
    whiteOpacity6: "rgba(27, 29, 33, .6)",
    whiteOpacity7: "rgba(27, 29, 33, .8)",

    textActive: "#E4E4E4",
    textLight: "#B2B3BD",

    textDarkActive: "#1B1D21",
    textDarkLight: "rgba(27, 29, 33, 0.7)",

    textDeActive: "#808191",

    success: "#7FBA7A",
    successLight: "#5D9470",
  },

  shadows: {
    darkLightGrayOpacity20: "rgba(60, 62, 66, 0.20)",
  },
});

export { css, darkTheme, getCssText, globalCss, styled };
