import type * as Stitches from "@stitches/react";
import { ComponentProps } from "@stitches/react";
import React, { forwardRef, PropsWithChildren } from "react";
import { darkTheme, styled } from "../../styles";

const BaseButton = styled("button", {
  alignItems: "center",
  backgroundColor: "$darkMain",
  /* A component that is used to display the user's name and avatar. */
  border: "none",
  borderRadius: "4px",
  color: "$white",
  cursor: "pointer",
  display: "flex",
  fontFamily: "$body",
  fontSize: "14px",
  fontWeight: "bold",
  justifyContent: "center",
  lineHeight: "20px",
  padding: "$2 $4",
  transition: "all .2s ease-in-out",
  width: "100%",

  "&:hover": {
    backgroundColor: "$primary",
  },

  "&:disabled": {
    backgroundColor: "$darkMain",
    cursor: "not-allowed",
    pointerEvents: "none",
  },

  variants: {
    loading: {
      true: {
        pointerEvents: "none",
      },
    },

    bordered: {
      true: {
        border: "2px solid $darkMain",
        color: "$darkMain",
        backgroundColor: "$white",
        padding: "calc($1 - 2px) calc($2 - 2px)",

        "&:hover": {
          backgroundColor: "$primaryShade2",
        },
      },
    },

    design: {
      textOnly: {
        backgroundColor: "transparent",
        color: "$textActive",

        "&:hover": {
          backgroundColor: "$primaryShade2",
        },
      },
      white: {
        backgroundColor: "$white",
        color: "$textActive",

        "&:hover": {
          backgroundColor: "$primaryShade2",
        },
      },
    },

    mode: {
      primary: {
        backgroundColor: "$primary",
      },

      inactive: {
        backgroundColor: "$darkShade4",
        color: "$textActive",
        cursor: "not-allowed",

        "&:hover": {
          backgroundColor: "$darkShade3",
        },
      },

      success: {
        backgroundColor: "$success",

        "&:hover": {
          backgroundColor: "$successLight",
        },
      },

      warning: {
        backgroundColor: "$accent2Shade4",

        [`.${darkTheme} &`]: {
          color: "$darkFullBlack",
        },

        "&:hover": {
          backgroundColor: "$accent2Shade3",
        },
      },
    },

    size: {
      normal: {},
      large: {},
      small: {
        borderRadius: "4px",
        fontSize: "10px",
        padding: "$1 $2",
        whiteSpace: "nowrap",
        width: "initial",
      },
    },

    inline: {
      true: {
        display: "inline-flex",

        "& + *": {
          marginLeft: "$1",
        },
      },
    },
  },
});

export type ButtonProps = ComponentProps<typeof BaseButton> &
  PropsWithChildren<Stitches.VariantProps<typeof BaseButton>>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { children, mode, loading, ...rest } = props;

    return (
      <BaseButton mode={mode} ref={ref} loading={loading} {...rest}>
        {loading ? "Loading" : children}
      </BaseButton>
    );
  },
);

Button.displayName = "Button";

export default Button;
