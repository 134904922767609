import React from "react";

export enum ThemeOptions {
  light = "light",
  dark = "dark",
}

export interface OjaUIContextProps {
  setTheme: (_theme: ThemeOptions) => void;
  theme: ThemeOptions;
}

export const OjaUI = React.createContext<OjaUIContextProps>({
  setTheme: () => null,
  theme: ThemeOptions.light,
});
