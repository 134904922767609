import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import CustomerTopNav from "../components/CustomerTopNav";
import { CUSTOM_DOMAIN_HEADER } from "../config/constants";
import { EcommerceAuthProvider } from "../contexts/auth.context";
import { LayoutOptions } from "@eleo/components/components/OjaUIProvider/OjaUIProvider";
import { EcommerceUIProvider } from "@eleo/components/components/OjaUIProvider/EcommerceUIProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-quill/dist/quill.snow.css";

export interface CustomAppProps extends AppProps {
  Component: React.ComponentType & {
    layoutOptions?: LayoutOptions;
  };
  isCustomDomain: boolean;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
    },
  },
});

export type CustomerAppLayoutOptions = {
  hideTopNavOnCustomDomain?: boolean;
  hideTopNav?: boolean;
};

export default function CustomerApp({
  Component,
  pageProps,
  isCustomDomain,
}: CustomAppProps) {
  const layoutOptions: CustomerAppLayoutOptions = Component.layoutOptions || {};

  const hideOnCustomDomain =
    isCustomDomain && layoutOptions.hideTopNavOnCustomDomain;

  const showTopNav = !hideOnCustomDomain && !layoutOptions.hideTopNav;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <EcommerceAuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
          />

          <EcommerceUIProvider>
            {showTopNav && <CustomerTopNav />}

            <Component {...pageProps} />
          </EcommerceUIProvider>
        </EcommerceAuthProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

CustomerApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const headers = appContext.ctx.res?.getHeaders();

  const isCustomDomain = Boolean(headers?.[CUSTOM_DOMAIN_HEADER]);

  return {
    ...appProps,
    isCustomDomain,
  };
};
